@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
}

html {
  color-scheme: light !important;
}

ul {
  list-style: disc !important;
  margin-left: 2.5rem !important;
}

ol {
  list-style: decimal !important;
  margin-left: 2.5rem !important;
}

h1 {
  @apply text-5xl font-extrabold leading-snug sm:text-6xl xl:text-7xl sm:leading-tight;
}

h2 {
  @apply text-4xl font-bold leading-tight tracking-tight sm:text-5xl xl:text-6xl;
}

h3 {
  @apply text-2xl md:text-3xl font-bold leading-tight tracking-tight lg:text-4xl;
}

h4 {
  @apply text-lg font-bold leading-tight tracking-tight md:text-xl lg:text-2xl;
}

h5 {
  @apply xl:text-xl text-lg;
}

.h1 {
  @apply text-5xl font-extrabold leading-snug sm:text-6xl xl:text-7xl sm:leading-tight;
}

.h2 {
  @apply text-4xl font-bold leading-tight tracking-tight sm:text-5xl xl:text-6xl;
}

.h3 {
  @apply text-2xl md:text-3xl font-bold leading-tight tracking-tight lg:text-4xl;
}

.h4 {
  @apply text-xl font-bold leading-tight tracking-tight md:text-2xl lg:text-3xl;
}
.h5 {
  @apply xl:text-xl text-lg;
}

#RFS-Label {
  @apply text-xl;
}

.slick-next::before,
.slick-prev::before {
  font-size: 40px;
  color: white;
  opacity: 0.8;
}

.slick-dots li button:before {
  font-size: 10px;
}

.react-flow__attribution a {
  display: none !important;
}

.leaflet-popup-content {
  width: 500px !important;
}

@media (max-width: 1024px) {
  .leaflet-control {
    display: none;
  }
}

.lg-sub-html {
  display: none;
}
